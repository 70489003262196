import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Grid,
  useMediaQuery,
  Radio,
  FormControlLabel,
  Button,
  RadioGroup,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CourseCard from "../common/courseEpisodeCard";
import { Link } from "gatsby";
import { RELATE_APP_BASE_URL } from "../../utils/constants";
function InstructorCard(props) {
  console.log(props, "props");
  const classes = useStyles();
  const theme = useTheme();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [courses, setCourses] = useState([]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setIsLoading] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    if (props?.data?.courses?.length > 0) {
      setCourses(props?.data?.courses);
    }
  }, [props?.data?.courses]);

  const handleSelectCategory = (e) => {
    if (props?.data?.courses?.length > 0) {
      const filterCourses = props?.data?.courses?.filter(
        (item) =>
          e.target.value === item?.categoryId?.toString() ||
          e.target.value === ""
      );
      setCourses([...filterCourses]);
    }
    setSelectedCategory(e.target.value);
  };

  /* function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
        setIsLoading(false);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      if (!src.includes(".webp")) {
        img.src = src;
        var ext = src.split(".");
        var text = ext[2] + "_thumb";
        ext.splice(2, 1, text);
        let newUrl = ext.join(".");

      setImage(src);
    });
  } */

  /*  useEffect(() => {
    preloadImage(`${RELATE_APP_BASE_URL}${props?.data?.coverUrl}`);
  }); */

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        alt={props?.data?.name}
        style={{
          filter: "blur(8)",
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #FFF 100%),url(${props?.ImageSrc})`,
        }}
      >
        <Grid container className={classes.profileGeneralContainer}>
          <Grid item xs={12} style={{ paddingTop: isMobile ? "3px" : "5px" }}>
            <IconButton
              size="small"
              style={{
                background: "#a9a9a9",
                float: "right",
                marginRight: "-15px",
                marginTop: "5px",
              }}
              onClick={() => props?.close()}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: isMobile ? "0px" : "30px" }}>
            <Avatar
              alt="instruct's image"
              src={`${RELATE_APP_BASE_URL}${props?.data?.pictureUrl}`}
              style={{
                width: isMobile ? "80px" : "150px",
                height: isMobile ? "80px" : "150px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={isMobile ? "h5" : "h3"}
              component={isMobile ? "h5" : "h3"}
              gutterBottom
              className={classes.instructorName}
            >
              {props?.data?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/*  <Link
              target="_blank"
              style={{ textDecoration: "none" }}
              // to={"subscription-plans"}
              to={`/teachers/${props?.data?.name?.replace(/\s/g, "-")}-${
                props?.data?.id
              }`}
            >
              <Button
                className={classes.button}
                style={{ backgroundColor: "#F3B527" }}
              >
                Detail
              </Button>
            </Link> */}
            <div className={classes.sectionMobile}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#FFFF",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/teachers/${props?.data?.name?.replace(/\s/g, "-")}-${
                  props?.data?.id
                }/`}
                target="_self"
              >
                <Button
                  aria-label="Detail"
                  variant="contained"
                  className={classes.letsStartBtn}
                  style={{
                    border: "2px solid #FF6035",
                    color: "#FF6035",
                    fontSize: isMD && "15px",
                  }}
                >
                  Detail
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </CardMedia>
      <CardContent
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: unescape(props?.data?.description),
              }}
            />
          </Grid>

          {/* <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
            <Typography
              variant="h5"
              component="h5"
              style={{ color: "#3D3D3D", paddingTop: "20px" }}
              gutterBottom
              className={classes.instructorName}
            >
              Courses
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6} lg={6} xl={6}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={selectedCategory}
              onChange={handleSelectCategory}
              style={{ paddingTop: isMobile ? "12px" : "20px" }}
            >
              <Grid container className={classes.hideScrollBar}>
                <Grid item>
                  <FormControlLabel
                    style={{
                      width: "max-content",
                      color: selectedCategory === "" ? "#09B1D3" : "#3D3D3D",
                    }}
                    value=""
                    control={<Radio style={{ color: "#09B1D3" }} />}
                    label="All"
                  />
                </Grid>
                {props?.categories
                  ?.filter((item) => item?.name !== "All")
                  ?.map((category) => {
                    return (
                      <Grid item>
                        <FormControlLabel
                          style={{
                            width: "max-content",
                            color:
                              selectedCategory === category?.id?.toString()
                                ? "#09B1D3"
                                : "#3D3D3D",
                          }}
                          value={(category?.id).toString()}
                          control={<Radio style={{ color: "#09B1D3" }} />}
                          label={category?.name}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            {courses?.length > 0 ? (
              courses?.map((item, ind) => {
                return (
                  <Grid item xs={12} key={item.id}>
                    <Link
                      to={`${RELATE_APP_BASE_URL}/courses/${item?.id}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <CourseCard
                        data={{
                          image: item?.image,
                          name: item?.name,
                          description: item?.remarks,
                        }}
                        ind={ind + 1}
                      />
                    </Link>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography
                  style={{
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                  }}
                >
                  NO ACTIVE COURSE AT THE MOMENT
                </Typography>
              </Grid>
            )}
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default InstructorCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //   borderRadius: "11px",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "-2px",
  },

  letsStartBtn: {
    textTransform: "none",
    // width: "100%",
    // height: "67px",
    // background: theme.palette.secondary.light,
    background: "transparent",

    borderRadius: "59px",
    border: "2px solid transparent",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    height: "35px",
    marginBottom: "115px",
    color: "#000",
    transition: "background 1s",
    width: "100px",
    // background: theme.palette.secondary.light,
    border: "2px solid #FF6035",

    "&:hover": {
      background: "#FF6035",
      border: "2px solid transparent",
      color: "#FFF !important",
    },
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C2C2C2",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  profileGeneralContainer: {
    position: "absolute",
    top: 0,
    padding: "0px 20px 20px 20px",
    // backgroundImage: 'linear-gradient(top bottom, transparent,white)'
  },

  profileImageContainer: {
    paddingTop: "10px",
  },
  instructorName: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#3D3D3D",
    fontStyle: "normal",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
  },
  mediaStyle: {
    filter: "blur(8)",
  },
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
