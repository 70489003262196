import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  useMediaQuery,
  IconButton,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Close, PlayArrow, StarBorder } from "@material-ui/icons";
import EpisodeCard from "../common/courseEpisodeCard";
import { Link } from "gatsby";
import ReactPlayer from "react-player/lazy";
import { RELATE_APP_BASE_URL } from "../../utils/constants";
import Rating from "@material-ui/lab/Rating";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import useFetch from "use-http";
// import IntroVideo from "../../assets/video/OmidIntro.mp4";
const IntroVideo =
  process.env.REACT_APP_INTRO_VIDEO ||
  "https://ipfsn.omidlife.com/ipfs/Qmf8khwqMWZ1DCT4AMQinJ4TYQcrRXUD1zSFZKUCEGKKCA";
function InstructorCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [previewDialog, setPreviewDialog] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [introVideo, setIntroVideo] = useState(true);

  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClosePreviewDialog = () => {
    setPreviewDialog(false);
    setThumbnail(null);
    setIntroVideo(true);
  };
  const handleOpenPreviewDialog = () => {
    setPreviewDialog(true);
  };
  console.log(props, "propssss");
  /*  useEffect(() => {
    async function fetchConfigs() {
      try {
        const _configs = await get("/config/");
        if (response.ok) {
          let obj = {};
          for (const item of _configs?.data) {
            obj = { ...obj, [item.key]: item.value };
          }
          setConfigs(obj);
        } else {
          fetchConfigs();
        }
      } catch (e) {}
    }

    fetchConfigs();
  }, []); */

  const handleEnded = () => {
    if (introVideo) {
      setIntroVideo(!introVideo);
    }
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        fullWidth={true}
        minWidth={"sm"}
        // maxWidth={'lg'}
        open={previewDialog}
        onClose={handleClosePreviewDialog}
        aria-labelledby="responsive-dialog-title"
        classes={{ root: classes.dialogRoot }}
      >
        <DialogContent>
          {thumbnail ? (
            <Card
              elevation={0}
              className={classes.ratio}
              style={{ borderRadius: "0px" }}
            >
              <img
                src={`${RELATE_APP_BASE_URL}${props?.data?.image}`}
                className={classes.imageCardRoot}
                style={{ borderRadius: "0px" }}
                alt={props?.data?.name}
                loading="lazy"
              />
            </Card>
          ) : (
            <div className={classes.playerWrapper}>
              <div className={clsx(classes.reactPlayer, classes.preLoadBody)}>
                {loadingProgress && (
                  <CircularProgress style={{ color: "#09A9BB" }} />
                )}
              </div>
              <ReactPlayer
                controls="true"
                height="100%"
                width="100%"
                className={classes.reactPlayer}
                // onProgress={handleProgress}
                onEnded={handleEnded}
                onStart={() => setLoadingProgress(false)}
                playing={true}
                // light={sessionThumbnail}
                onError={() => {
                  previewDialog &&
                    setThumbnail(`${RELATE_APP_BASE_URL}${props?.data?.image}`);
                }}
                url={introVideo ? IntroVideo : props?.data?.previewUrl}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                  file: {
                    attributes: {
                      preload: "none",
                    },
                  },
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          alt={`${props?.data?.name}`}
          image={`${RELATE_APP_BASE_URL}${props?.data?.image}`}
          style={{
            filter: "blur(8)",
          }}
        >
          <Grid container className={classes.profileGeneralContainer}>
            <Grid item xs={12} style={{ paddingTop: isMobile ? "3px" : "5px" }}>
              <IconButton
                size="small"
                style={{
                  background: "#a9a9a9",
                  float: "right",
                  marginRight: "-15px",
                  marginTop: "5px",
                }}
                onClick={() => props?.close()}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </CardMedia>
        <CardContent
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              /*   style={{
                paddingTop: isMobile ? "140px" : "10px",
              }} */
            >
              <Grid container /* className={classes.profileGeneralContainer} */>
                <Grid
                  item
                  xs={12}
                  /*   style={{
                paddingTop: isMobile ? "100px" : "100px",
                paddingBottom: "20px",
              }} */
                >
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    component={isMobile ? "h5" : "h4"}
                    gutterBottom
                    className={classes.courseName}
                  >
                    {props?.data?.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} container>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {/* {props?.data?.previewUrl && (
                  <Button
                    variant="contained"
                    color="default"
                    style={{ backgroundColor: "#09B1D3" }}
                    className={classes.button}
                    startIcon={<PlayArrow />}
                    onClick={handleOpenPreviewDialog}
                  >
                    Preview
                  </Button>
                )} */}

                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      // to={"subscription-plans"}
                      to={
                        props?.data?.plan?.name === "Subscription"
                          ? /* ? `${RELATE_APP_BASE_URL}/more/subscription-plan` */
                            `/subscription-plans`
                          : `${RELATE_APP_BASE_URL}/view-course/${props?.data?.slug}?tab=signup`
                      }
                    >
                      <Button
                        className={classes.button}
                        style={{ backgroundColor: "#4DB051" }}
                        aria-label="Join Now"
                      >
                        {props?.data?.plan?.name === "Paid"
                          ? `Join for ( ${props?.configs?.currency} ${props?.data?.price}  )`
                          : props?.data?.plan?.name === "Freemium"
                          ? `Join for ( ${props?.configs?.currency}${props?.data?.price} )`
                          : props?.data?.plan?.name === "Subscription"
                          ? "Subscribe"
                          : "Join for free"}
                      </Button>
                    </Link>

                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#FFFF",
                        display: "flex",
                        alignItems: "center",
                      }}
                      to={`/courses/${props?.data?.slug}/`}
                      target="_self"
                    >
                      <Button
                        variant="contained"
                        className={classes.letsStartBtn}
                        style={{
                          border: "2px solid #FF6035",
                          color: "#FF6035",
                          fontSize: isMD && "15px",
                        }}
                        aria-label="Detail"
                      >
                        Detail
                      </Button>
                    </Link>

                    {/*  <Link
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  // to={"subscription-plans"}
                  to={`/courses/${props?.data?.slug}`}
                >
                  <Button
                    className={classes.button}
                    style={{ backgroundColor: "#F3B527" }}
                  >
                    Detail
                  </Button>
                </Link> */}
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                      <Rating
                        name="read-only"
                        value={props?.data?.averageRating}
                        readOnly
                        emptyIcon={
                          <StarBorder
                            fontSize="inherit"
                            style={{ color: "#FFB400" }}
                          />
                        }
                      />
                    </Grid>
                    {props?.data?.reviewsCount && (
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography style={{ color: "#83C032" }}>
                          ({props?.data?.reviewsCount})
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  container
                  style={{ height: "30px", paddingTop: "10px" }}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography variant="h3" className={classes.description}>
                      Teacher :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography variant="h3" className={classes.description}>
                      {props?.data?.user?.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography variant="h3" className={classes.description}>
                      Category :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography variant="h3" className={classes.description}>
                      {props?.data?.category?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <div
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: unescape(props?.data?.remarks),
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h5"
                component="h5"
                style={{ color: "#3D3D3D", paddingTop: "30px" }}
                gutterBottom
                className={classes.courseName}
              >
                Episodes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props?.data?.episodes?.length > 0 ? (
                props?.data?.episodes?.map((item, ind) => {
                  return (
                    <Grid item xs={12} key={item.id}>
                      <Link
                        to={`${RELATE_APP_BASE_URL}/courses/${props?.data?.slug}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <EpisodeCard
                          data={{
                            image: item?.thumbnail,
                            name: item?.name,
                            description: item?.description,
                          }}
                          ind={ind + 1}
                        />
                      </Link>
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#000",
                    }}
                  >
                    No Data found
                  </Typography>
                </Grid>
              )}
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default InstructorCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //   borderRadius: "11px",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "-2px",
  },
  button: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    borderRadius: "59px",
    height: "35px",
    marginTop: "10px",
    fontSize: "15px",
    fontStyle: "normal",
    fontFamily: theme.palette.fontFamily.Reef,
    //    height: '52px'
  },

  letsStartBtn: {
    textTransform: "none",
    // width: "100%",
    // height: "67px",
    // background: theme.palette.secondary.light,
    background: "transparent",
    borderRadius: "59px",

    border: "2px solid transparent",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    height: "35px",
    color: "#000",
    transition: "background 1s",
    width: "100px",
    // background: theme.palette.secondary.light,
    border: "2px solid #FF6035",

    "&:hover": {
      background: "#FF6035",
      border: "2px solid transparent",
      color: "#FFF !important",
    },
  },

  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C2C2C2",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  profileGeneralContainer: {
    position: "absolute",
    top: 0,
    padding: "0px 20px 20px 20px",

    // backgroundImage: 'linear-gradient(top bottom, transparent,white)'
  },

  profileImageContainer: {
    paddingTop: "10px",
  },
  courseName: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#3D3D3D",
    fontStyle: "normal",
    fontSize: "15px",
    //  fontStyle: "normal",
    //  fontWeight: "500",
  },
  mediaStyle: {
    filter: "blur(8)",
  },

  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
    // paddingTop: `${(157/258)*100}%`
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  imageCardRoot: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#a9a9a9",
    backgroundPosition: "center",
  },
  ratio: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: `56.25%`,
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
  dialogRoot: {
    backgroundColor: "transparent",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));
