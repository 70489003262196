import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InstructorProfileCard from "./instructorProfileCard";
import { Link, navigate } from "gatsby";
import { useMediaQuery, Dialog, DialogContent, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { RELATE_APP_BASE_URL } from "../../utils/constants";
import Truncate from "react-truncate";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    // height: "100%",
    // backgroundColor: "#FFFFFF",
  },
  mediaImage: {
    width: "100%",
    height: "100%",
    /*   position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    aspectRatio: 16 / 9, */
  },
  media: {
    height: "290px",
    // position: "relative",
    //  paddingTop: "100%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    //  background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    flex: 1.3344,
    position: "relative",
    // paddingTop: "56.25%",
  },

  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C2C2C2",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  perviewBtnContainer: {
    position: "absolute",
    bottom: "62px",

    left: "5px",
    // borderRadius: "13px",
    // padding: "0px 10px",
    // // borderTopRightRadius: "0px",
    // borderBottomRightRadius: "0px",
    // fontFamily: theme.palette.fontFamily.Reef,
    // color: "#fff",
    // fontStyle: "normal",
    // fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
    width: "90px",
    textDecoration: "none",
    zIndex: "10",
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
    //    height: '52px'
  },
  previewButton: {
    display: "none",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  previewButtonDisplay: {
    display: "block",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
}));

function InstructorCard({ data, categories }) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const [displayHover, setDisplayHover] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [imageSrcPosition, setImageSrcPosition] = useState();

  const handleClickOpen = (e, data) => {
    // e.preventDefault();
    e.stopPropagation();
    setUserData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setUserData("");
    setOpen(false);
  };

  /*   function preloadImage(src) {
    if (!src.includes(".webp")) {
      var ext = src.split(".");

      var text = ext[2] + "_thumb";
      ext.splice(2, 1, text);
      let newUrl = text + ".webp";
      newUrl = newUrl.split("/");
      newUrl.splice(0, 1);
      newUrl = newUrl.join("/");

      // debugger;
      // console.log(ext, "******")
      //  console.log(newUrl,"*newUrl*****")
      // let result = src.replace("png", "webp");
      //  setImage(newUrl);
      setImage(src);
    });
  } */

  /*  useEffect(() => {
    preloadImage(`${RELATE_APP_BASE_URL}${data?.pictureUrl}`);
  }, []); */
  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
          classes={{ root: classes.dialogRoot }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <InstructorProfileCard
              data={userData}
              categories={categories}
              close={handleClose}
              // ImageSrc={`${RELATE_APP_BASE_URL}/${image}`}
              ImageSrc={`${RELATE_APP_BASE_URL}${data?.pictureUrl}`}
            />
          </DialogContent>
        </Dialog>
      </Grid>

      {/* <div
        onClick={(e) => {
          navigate(
            `{/teachers/${data?.name?.replace(/\s/g, "-")}-${data?.id}}`
          );
          console.log("data id", data?.id);
        }}
      > */}
      <Link
        style={{
          textDecoration: "none",
          color: "#FFFF",
          display: "flex",
          alignItems: "center",
        }}
        to={`/teachers/${data?.name?.replace(/\s/g, "-")}-${data?.id}/
        `}
        target="_self"
      >
        <Card
          className={classes.root}
          onMouseEnter={() => setDisplayHover(true)}
          onMouseLeave={() => setDisplayHover(false)}
        >
          <div className={classes.media}>
            {/*  {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={325}
              />
            ) : null} */}
            <img
              src={`${RELATE_APP_BASE_URL}${data?.pictureUrl}`}
              // src={
              //   imageSrcPosition > 1
              //     ? image + `&dim=${300}x${150} `
              //     : image + `?dim=${300}x${150} `
              // }
              // src={`${RELATE_APP_BASE_URL}/${image}`}
              onLoad={() => {
                setIsLoading(false);
              }}
              onError={(err) => {
                setIsLoading(false);
              }}
              className={classes.mediaImage}
              alt={data?.name}
              style={{
                height: !isMobile && "100%",
                maxHeight: !isMobile && "100%",
                //   display: isLoading ? "none" : "block"
              }}
              loading="lazy"
            />
          </div>
          {/* <CardActionArea> */}
          {/*    {data?.pictureUrl ? (
            <>
              <CardMedia
                className={classes.media}
                alt={data?.name}
                image={`${RELATE_APP_BASE_URL}${data?.pictureUrl}`}
                // image={`${RELATE_APP_BASE_URL}/${image}`}
              ></CardMedia>
            </>
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={"290px"}
              sx={{ bgcolor: "#C4C4C4" }}
            />
          )} */}

          <CardContent
            style={{
              backgroundColor: "#FFFFFF",
              padding: "8px",
              maxHeight: "50px",
              height: "50px",
              overflow: "hidden",
            }}
            onMouseEnter={() => setDisplayHover(true)}
            onMouseLeave={() => setDisplayHover(false)}
          >
            <Typography
              gutterBottom
              // variant="h5"
              // component="h2"
              variant="h2"
              className={classes.heading}
              style={{ fontSize: isMobile && "18px" }}
            >
              {`   ` + data?.name + `   `}
            </Typography>
            <Typography
              gutterBottom
              // variant="h5"
              variant="h2"
              className={classes.date}
              style={{ fontSize: isMobile && "14px" }}
            >
              <Truncate lines={1} ellipsis={<span>...</span>}>
                {`   ` + data?.title + `   `}
              </Truncate>
            </Typography>
          </CardContent>
          {/* </CardActionArea> */}
        </Card>
      </Link>
      {/* </div> */}
      <div
        className={classes.perviewBtnContainer}
        onMouseEnter={() => setDisplayHover(true)}
        onMouseLeave={() => setDisplayHover(false)}
      >
        <Button
          aria-label="Preview"
          variant="contained"
          color="default"
          style={{ backgroundColor: "#FF6035", borderRadius: "35px" }}
          className={
            displayHover || isMobile
              ? classes.previewButtonDisplay
              : classes.previewButton
          }
          onClick={(e) => handleClickOpen(e, data)}

          // startIcon={<PlayArrow />}
        >
          Preview
        </Button>
      </div>
    </div>
  );
}

export default InstructorCard;
